(function ($, Drupal) {
  'use strict';

  /**
   * MixItUp filter for shade-picker
   * https://mixitup.kunkalabs.com/
   */
  Drupal.behaviors.sppShadeFilterPickerMixitup = {
    attach: function (context) {
      var $miu_container = $('.shade-picker-filter-grid', context);

      // Hack to lazy-load background images for smooshes
      $('.shade-picker__color-chip', $miu_container).once('shade-picker__color-chip').hover(
        function () {
          var $sib = $(this).siblings('.shade-picker__color-texture');

          if ($sib.length) {
            var img = $sib.data('bg-image');

            if (img.length > 0) {
              $sib.css('background-image', 'url(' + img + ')');
            }
          }
        },
        function () {
          // do nothing on purpose
        }
      );

      $miu_container.mixItUp({
        selectors: {
          target: '.shade-picker__color'
        }
      });
    }
  };
})(jQuery, Drupal);
